import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout/layout"
import SeoComponent from "@components/seo"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import HeadingBG from "@images/privacy.jpg"
import PageHeading from "@components/pageHeading"

const PrivacyPolicy = ({ data, location }) => {
  return (
    <Layout headerType="solid" location={location}>
      <GatsbySeo noindex={true} />
      <SeoComponent
        title="Custom Woodworking Shops Near Me - Thank You"
        description="For high quality handmade wood furniture, Eden Oaks provides custom woodworking in Colorado Springs and surrounding areas. Call us to discuss your project!"
      />
      <PageHeading bgImage={HeadingBG}>
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-10">
              <h1 className="display-4 text-white">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </PageHeading>
      <div className="solid-header-page-spacer" />
      <div className="pb-10">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <p className="mb-0">
                Eden Oaks ("us", "we", or "our") operates{""}
                <Link to="/">https://edenoakswoodware.com/</Link> (the "Site").
                This page informs you of our policies regarding the collection,
                use and disclosure of Personal Information we receive from users
                of the Site.
              </p>
              <p>
                We use your Personal Information only for providing and
                improving the Site. By using the Site, you agree to the
                collection and use of information in accordance with this
                policy.
              </p>
              <h3 className="mb-4">Information Collection and Use</h3>
              <p>
                While using our Site, we may ask you to provide us with certain
                personally identifiable information that can be used to contact
                or identify you. Personally identifiable information may
                include, but is not limited to your name ("Personal
                Information").
              </p>
              <h3 className="mb-4">Log Data</h3>
              <p>
                Like many site operators, we collect information that your
                browser sends whenever you visit our Site ("Log Data").
              </p>
              <p>
                This Log Data may include information such as your computer's
                Internet Protocol ("IP") address, browser type, browser version,
                the pages of our Site that you visit, the time and date of your
                visit, the time spent on those pages and other statistics, which
                we may sometimes aggregate with similar information from other
                customers to create features like Top Sellers.
              </p>
              <p>
                In addition, we may use third party services such as Google
                Analytics that collect, monitor and analyze this data, as well
                as browser data such as cookies for fraud prevention and other
                purposes. During some visits we may use software tools to
                measure and collect session information, including page response
                times, download errors, lengths of visits to certain pages, page
                interaction information (such as scrolling, clicks, and
                mouse-overs), and methods used to browse away from the page. We
                may also collect technical information to help us identify your
                device for fraud prevention and diagnostic purposes.
              </p>
              <h3 className="mb-4">How We Use Your Information</h3>
              <p>
                We use your information (including any information we collect,
                as descried in this Privacy Policy) for various purposed=s
                depending on the types of information we have collected from you
                and about you and the specific services you use, including to:
              </p>
              <ul>
                <li>Complete a purchase</li>
                <li>Respond to your request for information</li>
                <li>
                  Provide you with product related updates and information about
                  products you have purchased from us
                </li>
                <li>Provide you with notifications via email</li>
                <li>
                  Contact you by phone, email or postal mail regarding Eden Oaks
                  products, surveys, services, promotions, special events and
                  other subject we think may be of interest to you
                </li>
                <li> Customize the advertising and content you see</li>
                <li>Help us better understand your interests and needs</li>
                <li>
                  {" "}
                  Synthesize and derive insights from you purchases of different
                  Eden Oaks products
                </li>
                <li> Understand and resolve Site issues being reported</li>
              </ul>
              <h3 className="mb-4">Communications</h3>
              <p>
                We may use your personal information to contact you for
                marketing (newsletters, marketing or promotional materials and
                other information) and marketing analytic purposes. The purpose
                for this is to enhance the experience and optimize the quality
                of our services. If you for any reason wish not to receive any
                marketing, please contact us with the subject that you do not
                wish to be contacted with any promotional purposes by sending an
                email to morgan@edenoaksco.com or use links that we provide in
                email communication with you for controlling the personal
                information that we store.
              </p>
              <h3 className="mb-4"> Cookies</h3>
              <p>
                Cookies are files with small amount of data, which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a web site and stored on your computer's hard drive.
              </p>
              <p>
                Like many sites, we use "cookies" to collect information. You
                can instruct your browser to refuse all cookies or to indicate
                when a cookie is being sent. However, if you do not accept
                cookies, you may not be able to use some portions of our Site.
              </p>
              <h3 className="mb-4">Security</h3>
              <p>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet,
                or method of electronic storage, is 100% secure. While we strive
                to use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
              </p>
              <h3 className="mb-4">Changes To This Privacy Policy</h3>
              <p>
                This Privacy Policy is effective as of 3/26/2021 and will remain
                in effect except with respect to any changes in its provisions
                in the future, which will be in effect immediately after being
                posted on this page.
              </p>
              <p>
                We reserve the right to update or change our Privacy Policy at
                any time and you should check this Privacy Policy periodically.
                Your continued use of the Service after we post any
                modifications to the Privacy Policy on this page will constitute
                your acknowledgment of the modifications and your consent to
                abide and be bound by the modified Privacy Policy.
              </p>
              <p>
                If we make any material changes to this Privacy Policy, we will
                notify you either through the email address you have provided
                us, or by placing a prominent notice on our website.
              </p>
              <h3 className="mb-4"> Contact Us</h3>
              <p>
                If you have any questions about this Privacy Policy, please{" "}
                <Link to="/contact-us">contact us.</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
